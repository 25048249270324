import { PayloadAction, createSlice } from "@reduxjs/toolkit"


export interface UsageState {
    bandwith: number
    maxBandwith: number
    customDownloads: number
    maxCustomDownloads: number
    bandwidthPercentage: number
    customDownloadsPercentage: number,
    additionalBandwidth: number
    additionalConsumendBandwidth: number
    addotionalConsumendBandwidthPercentage: number
    plan: string
}

const initialState: UsageState = {
    bandwith: 0,
    maxBandwith: 0,
    customDownloads: 0,
    maxCustomDownloads: 0,
    bandwidthPercentage: 0,
    customDownloadsPercentage: 0,
    additionalBandwidth: 0,
    additionalConsumendBandwidth: 0,
    addotionalConsumendBandwidthPercentage: 0,
    plan: "free"
}

export interface updateState {
    bandwith?: number
    maxBandwith?: number
    customDownloads?: number
    maxCustomDownloads?: number,
    additionalBandwidth?: 0,
    additionalConsumendBandwidth?: 0,
    plan?: string
}

const fromBackendPlanToPlan = (backendPlan: string | undefined) => {
    // remove "-group" from the plan name
    if (!backendPlan) return undefined
    return backendPlan.split("-")[0]
}

export const usageSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateUsage: (state, update: PayloadAction<updateState>) => {
            state.bandwith = update.payload.bandwith || state.bandwith
            state.maxBandwith = update.payload.maxBandwith || state.maxBandwith
            state.customDownloads = update.payload.customDownloads || state.customDownloads
            state.maxCustomDownloads = update.payload.maxCustomDownloads || state.maxCustomDownloads
            state.additionalBandwidth = update.payload.additionalBandwidth || state.additionalBandwidth
            state.additionalConsumendBandwidth = update.payload.additionalConsumendBandwidth || state.additionalConsumendBandwidth

            state.bandwidthPercentage = Math.ceil((state.bandwith / state.maxBandwith) * 100)
            state.customDownloadsPercentage = Math.ceil((state.customDownloads / state.maxCustomDownloads) * 100)
            state.addotionalConsumendBandwidthPercentage = Math.ceil((state.additionalConsumendBandwidth / state.additionalBandwidth) * 100)

            state.plan = fromBackendPlanToPlan(update.payload.plan) || state.plan

        }
    }
})


export const { updateUsage } = usageSlice.actions