import { Container, Grid, Text, Card, Button, Spacer } from "@nextui-org/react"
import BondsComponent from "../components/bonds-component/bonds.component"
import { EaTutorialComponent } from '../components/ea-tutorial/ea-tutorial.component'
import { ApiKeyComponent } from "../components/api-key/api-key.component"
import { formatFileSize } from "../shared/utils/utils"
import { CircularProgressbar } from "react-circular-progressbar"
import { useState, useEffect } from "react"
import { useQuantdleApi } from "../shared/hooks/quantdle-api.hook"
import { useAppDispatch, useAppSelector } from "../shared/hooks/redux.hook"
import { updateUsage } from "../shared/redux/usage-slice"
import { ScoreComponent } from "../components/scores/scores.component"
import { OneClickDownloadComponentV2 } from "../components/one-click-download/one-click-download-v2.component"

interface HomePageV2Styles {
    section: React.CSSProperties
}

export interface UserInfo {
    dataConsumption: number
    maxAllowedDataConsumption: number
    dataComsumptionPercentage: number
    customDownloads: number
    maxAllowedCustomDownloads: number
    customDownloadsPercentage: number
    plan: string
}

export const HomePageV2 = () => {

    const dispatch = useAppDispatch()
    const usage = useAppSelector(state => state.usage)
    
    const { usersApi } = useQuantdleApi()
    const [user, setUser] = useState<UserInfo>({
        dataConsumption: 0,
        maxAllowedDataConsumption: 0,
        dataComsumptionPercentage: 0,
        customDownloads: 0,
        maxAllowedCustomDownloads: 0,
        customDownloadsPercentage: 0,
        plan: 'free'
    })
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)
    
    
    useEffect(() => {
        // update the redux use ussage
        dispatch(updateUsage({
            bandwith: user.dataConsumption,
            maxBandwith: user.maxAllowedDataConsumption,
            customDownloads: user.customDownloads,
            maxCustomDownloads: user.maxAllowedCustomDownloads,
            plan: user.plan
        }))
    
    }, [user])
    
    useEffect(() => {
    
        // check that user api exists
        if(!usersApi) return
    
        // call the api to get the user info
        usersApi.getUserInfo().then((user) => {
    
        setUser({
            dataConsumption: user.data.dataConsumption ?? 0,
            maxAllowedDataConsumption: user.data.maxAllowedDataConsumption ?? 0,
            maxAllowedCustomDownloads: user.data.maxAllowedCustomDownloads ?? 0,
            customDownloads: user.data.numberOfCustomDownloads ?? 0,
            dataComsumptionPercentage: Math.ceil(user.data.dataConsumption / user.data.maxAllowedDataConsumption * 100) ?? 0,
            customDownloadsPercentage: Math.ceil(user.data.numberOfCustomDownloads / user.data.maxAllowedCustomDownloads * 100) ?? 0,
            plan: user.data.plan ?? 'free'
        })

        })
    }, [usersApi])

    const styles: HomePageV2Styles = {
        section: {
            padding: '2rem 0',
        },
    }

    return (
        <Container lg>
            {/* Usage Section */}
            <Spacer y={2}/>
            <Text h3 style={{display: 'flex', alignItems: 'center'}}>Statistics</Text>
            <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Quickly check the status of your account.</Text>
            <ScoreComponent />
            <Spacer y={2}/>
            {/* API Key and Software Download Section */}
            <Text h3 style={{display: 'flex', alignItems: 'center'}}>Programatic access</Text>
            <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Access to the data programtically.</Text>
            <div style={styles.section}>
                <Grid.Container gap={2}>
                    {/* API Key Management */}
                    <Grid xs={12} md={6}>
                        <ApiKeyComponent />
                    </Grid>

                    {/* Software Utility Video and Download */}
                    <Grid xs={12} md={6}>
                        <BondsComponent />
                    </Grid>
                </Grid.Container>
            </div>
            <Spacer y={2}/>
            {/* Bonds Section */}
            <Text h3 style={{display: 'flex', alignItems: 'center'}}>Bonds</Text>
            <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Out of bandwith? Purchase a bond to continue upadating data.</Text>
            <EaTutorialComponent />
            <Spacer y={2}/>
            {/* Manual Download */}
            <Text h3 style={{display: 'flex', alignItems: 'center'}}>Manual Download</Text>
            <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Download data manually id you wnat to use the data for other task rather than importing on MT5.</Text>
            <OneClickDownloadComponentV2 /> 
        </Container>
)
}