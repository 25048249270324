import { Card, Grid, Text, Button } from "@nextui-org/react"
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import { useAppDispatch, useAppSelector } from "../../shared/hooks/redux.hook";
import { useEffect, useState } from "react";
import { formatFileSize } from "../../shared/utils/utils";
import { updateUsage } from "../../shared/redux/usage-slice";
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook";
import { QuantdleLoader } from "../quantdle-loader/loader.component";
import { fetchUserAttributes } from "aws-amplify/auth";

export interface UserInfo {
    dataConsumption: number
    maxAllowedDataConsumption: number
    dataComsumptionPercentage: number
    customDownloads: number
    maxAllowedCustomDownloads: number
    customDownloadsPercentage: number
    additionalBandwidth: number
    additionalConsumendBandwidth: number
    additonalConsumendBandwidthPercentage: number
    plan: string
}

export const ScoreComponent = () => {


  const dispatch = useAppDispatch()
  const usage = useAppSelector(state => state.usage)

    const { usersApi, downloadsApi } = useQuantdleApi()
    const [user, setUser] = useState<UserInfo>({
        dataConsumption: 0,
        maxAllowedDataConsumption: 0,
        dataComsumptionPercentage: 0,
        customDownloads: 0,
        maxAllowedCustomDownloads: 0,
        customDownloadsPercentage: 0,
        additionalBandwidth: 0,
        additionalConsumendBandwidth: 0,
        additonalConsumendBandwidthPercentage: 0,
        plan: 'free'
    })
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)

    const getUserData = async () => {

        if (!usersApi) return

        if (!downloadsApi) return

        // call the api to get the user info
        const user = await usersApi.getUserInfo()

        const bondsData = await downloadsApi.bondsGet()

        // set the user data
        const additionalBandwidth = bondsData.data.reduce((acc, bond) => acc + bond.bondQuantity, 0)
        const consumedAdditionalBandwidth = bondsData.data.reduce((acc, bond) => acc + bond.consumedBondData, 0)

        setUser({
            dataConsumption: user.data.dataConsumption ?? 0,
            maxAllowedDataConsumption: user.data.maxAllowedDataConsumption ?? 0,
            maxAllowedCustomDownloads: user.data.maxAllowedCustomDownloads ?? 0,
            customDownloads: user.data.numberOfCustomDownloads ?? 0,
            dataComsumptionPercentage: Math.ceil(user.data.dataConsumption / user.data.maxAllowedDataConsumption * 100) ?? 0,
            customDownloadsPercentage: Math.ceil(user.data.numberOfCustomDownloads / user.data.maxAllowedCustomDownloads * 100) ?? 0,
            additionalBandwidth: additionalBandwidth,
            additionalConsumendBandwidth: consumedAdditionalBandwidth,
            additonalConsumendBandwidthPercentage: Math.ceil(consumedAdditionalBandwidth / additionalBandwidth * 100) ?? 0,
            plan: user.data.plan ?? 'free'

        })

    }


    


    useEffect(() => {
        // update the redux use ussage
        dispatch(updateUsage({
            bandwith: user.dataConsumption,
            maxBandwith: user.maxAllowedDataConsumption,
            customDownloads: user.customDownloads,
            maxCustomDownloads: user.maxAllowedCustomDownloads,
            additionalBandwidth: user.additionalBandwidth,
            additionalConsumendBandwidth: user.additionalConsumendBandwidth,
            plan: user.plan
        }))

    }, [user])

    useEffect(() => {

        getUserData()
        
    },   [usersApi, downloadsApi])

  // is userApi is not defined (the token has not been yet retrieved), show the loader
    if(!usersApi) return (<QuantdleLoader />)




    return (
        <Grid style={{marginLeft: 20}}>
            <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 5, flexWrap: 'wrap'}}>
                <Card variant="bordered" css={{ p: "$6", mw: "400px", margin: 20, borderColor: 'var(--nextui-colors-secondary)'}}>
                <Card.Body>
                    <Grid css={{ display: 'flex'}}>
                    {/* <LuGauge color="var(--nextui-colors-secondaryLightContrast)" style={{width: '75px', height: '50px', borderRadius: 14, background: 'var(--nextui-colors-secondaryLight)'}}/> */}
                    <Grid xs={12} style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                        <Text color="primary" h3 css={{ lineHeight: "$xs", margin: 0 }}>
                            {formatFileSize(usage.bandwith, 0)} <Text small css={{ color: "$accents8" }}>/ {formatFileSize(usage.maxBandwith, 0)}</Text>
                        </Text>
                        <Text b>Bandwidth usage</Text>
                    </Grid>
                    <Grid>
                        <CircularProgressbar styles={{root: {
                        width: 50,
                        },
                        path: {
                        stroke: 'var(--nextui-colors-secondaryLightContrast)',
                        },
                        trail: {
                        stroke: 'var(--nextui-colors-secondaryLight)',
                        },
                        text: {
                        fill: 'var(--nextui-colors-secondaryLightContrast)',
                        }
                        
                        }} value={usage.bandwidthPercentage} text={`${usage.bandwidthPercentage}%`} />
                    </Grid>
                    </Grid>
                </Card.Body>
                </Card>
                <Card variant="bordered" css={{ p: "$6", mw: "400px", margin: 20, borderColor: 'var(--nextui-colors-warning)'}}>
                <Card.Body>
                    <Grid css={{ display: 'flex'}}>
                    {/* <LuGauge color="var(--nextui-colors-secondaryLightContrast)" style={{width: '75px', height: '50px', borderRadius: 14, background: 'var(--nextui-colors-secondaryLight)'}}/> */}
                    <Grid xs={12} style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                        <Text color="warning" h3 css={{ lineHeight: "$xs", margin: 0 }}>
                            {formatFileSize(usage.additionalConsumendBandwidth, 0)} <Text small css={{ color: "$accents8" }}> / {formatFileSize(usage.additionalBandwidth)}</Text>
                        </Text>
                        <Text b>Bonds Bandwidth</Text>
                    </Grid>
                    <Grid>
                        <CircularProgressbar styles={{root: {
                        width: 50,
                        },
                        path: {
                            stroke: 'var(--nextui-colors-warningLightContrast)',
                        },
                        trail: {
                            stroke: 'var(--nextui-colors-warningLight)',
                        },
                        text: {
                            fill: 'var(--nextui-colors-warningLightContrast)',
                        }
                        
                        }} value={usage.additionalBandwidth === 0 ? 100 : usage.addotionalConsumendBandwidthPercentage} text={`${usage.additionalBandwidth === 0 ? 100 : usage.addotionalConsumendBandwidthPercentage}%`} />
                    </Grid>
                    </Grid>
                </Card.Body>
                </Card>
                <Card variant="bordered" css={{ p: "$6", mw: "400px", margin: 20, borderColor: 'var(--nextui-colors-success)'}}>
                <Card.Body>
                    <Grid css={{ display: 'flex'}}>
                    {/* <LuGauge color="var(--nextui-colors-secondaryLightContrast)" style={{width: '75px', height: '50px', borderRadius: 14, background: 'var(--nextui-colors-secondaryLight)'}}/> */}
                    <Grid xs={12} style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                        <Text transform="capitalize" h3 css={{ lineHeight: "$xs", margin: 0 }}>
                            {
                                // the group is given by {groupNmae}-group, get rid of the -group
                                usage.plan ?? 'Free'
                            }
                        </Text>
                        <Text css={{ color: "$accents8" }}>Account Type</Text>
                    </Grid>
                    <Grid style={{alignItems: 'center', display: 'flex'}}>
                        <Button auto flat color={'success'} onPress={() => setShowPlanModal(true)}>Upgrade</Button>
                    </Grid>
                    </Grid>
                </Card.Body>
                </Card>
            </Grid>
        </Grid>
    
    )
}
