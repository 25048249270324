import React, { useEffect, useState } from 'react';
import { Card, Grid, Text, Button, Table, Progress, Container, Row } from '@nextui-org/react';
import { RiBarChartLine } from "react-icons/ri";
import { LuDatabase } from "react-icons/lu";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { useQuantdleApi } from '../../shared/hooks/quantdle-api.hook';
import { Bond } from '../../shared/download-api/models';
import { formatFileSize } from '../../shared/utils/utils';
import { FaArrowRightLong } from "react-icons/fa6";
import { error } from 'console';
import { set } from 'date-fns';
import { fetchUserAttributes } from 'aws-amplify/auth';



// Mock data for bonds and usage
const availableBonds = [
  { id: 1, size: '1GB', price: '$9.99', features: ['Instant activation', 'Expires in 1M', 'Usage analytics'] },
  { id: 2, size: '5GB', price: '$39.99', features: ['5% discount', 'Expires in 1M', 'Usage analytics'] }
];

const bondIdToPaymentLink = {
  1: 'https://buy.stripe.com/test_dR600Sd3h1IXbvO7su?prefilled_email={{email}}',
  2: 'https://buy.stripe.com/test_dR600Sd3h1IXbvO7su?prefilled_email={{email}}'
};

function BondsComponent() {
  const [activeTab, setActiveTab] = useState('bonds');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showBonds, setShowBonds] = useState(false);
  const [bonds, setBonds] = useState<Bond[]>([]);
  const {downloadsApi} = useQuantdleApi()
  const [email, setEmail] = useState<string | undefined>()
  const [id, setId] = useState<string | undefined>()


  function handlePurchaseBond(bondId: number) {
      // open a stripe payment link addint to the link the prefilled email, use the bond Id to get the url

      if (bondId != 1 && bondId != 2) {
        console.error('Invalid bondId', bondId);
        return;
      }

      let bondLink = bondIdToPaymentLink[bondId];

      if (!bondLink) {
        console.error('No payment link found for bondId', bondId);
        return;
      }

      // add the prefilled email 
      bondLink = bondLink.replace('{{email}}', encodeURIComponent(email) ?? '');

      window.open(bondLink, '_blank');
  }

  async function handleFetchUserAttributes() {
              try {
                  const userAttributes = await fetchUserAttributes();
                  setEmail(userAttributes?.email);
                  setId(userAttributes?.sub);
              } catch (error) {
                  console.log(error);
              }
          }

  useEffect(() => {

    handleFetchUserAttributes()

    setShowBonds(false)
    if (!downloadsApi) return

    downloadsApi.bondsGet().then((response) => {
      console.log(response)
      setBonds(response.data)
      setShowBonds(true)
    }, (error: any) => {
      console.log(error)
    }).finally(() => {
      setShowBonds(true)
    })
  }, [downloadsApi])

  const fromFullStringDateToShortString = (date: string) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  return (
    <div>
      <Container>
        <div>
          {/* Header Section */}
          <div>

            {/* Navigation Buttons */}

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '10px'}}>
              <Button
                auto
                style={{marginRight: '10px'}}
                flat
                color={activeTab === 'bonds' ? 'primary' : 'default'}
                icon={<LuDatabase size={20} />}
                onClick={() => {
                  setActiveTab('bonds');
                  setMobileMenuOpen(false);
                }}
              >
                Bonds
              </Button>
              <Button
                auto
                flat
                color={activeTab === 'usage' ? 'primary' : 'default'}
                icon={<RiBarChartLine size={20} />}
                onClick={() => {
                  setActiveTab('usage');
                  setMobileMenuOpen(false);
                }}
              >
                History
              </Button>
            </div>
          </div>

          {/* Content Section */}
          {activeTab === 'bonds' ? (
            <Grid.Container gap={2}>
              {availableBonds.map((bond) => (
                <Grid key={bond.id} xs={12} sm={6}>
                  <Card>
                    <Card.Body>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10}}>
                        <Text h3 color="primary">{bond.size}</Text>
                        <Text h4>{bond.price}</Text>
                      </div>
                      <div style={{marginBottom: 10}}>
                        {bond.features.map((feature, index) => (
                          <div key={index} style={{display: 'flex', alignItems: 'center', marginLeft: 10}}>
                            <Text size={14}>{feature}</Text>
                          </div>
                        ))}
                      </div>
                      <Button
                        icon={<BiPurchaseTagAlt size={18} />}
                        color="primary"
                        size="lg"
                        css={{ width: '100%' }}
                        onClick={() => handlePurchaseBond(bond.id)}
                      >
                        Purchase Bond
                      </Button>
                    </Card.Body>
                  </Card>
                </Grid>
              ))}
            </Grid.Container>
          ) : (
            <Card>
              <Card.Body className="overflow-x-auto">
                {bonds.length == 0 && (
                  <div style={{display:'flex', 'flexDirection': 'column', justifyItems: 'center', alignItems: 'center', justifySelf: 'center', backgroundColor: '#8080801a', borderRadius: 12, padding: 8}} className="flex flex-col items-center justify-center py-8 px-4 space-y-4 mb-4 bg-gray-50 rounded-lg">
                    <Text h4 className="text-center text-gray-600">No active bandwidth bonds found</Text>
                    <Button
                      color="primary"
                      icon={<FaArrowRightLong size={18} />}
                      onClick={() => setActiveTab('bonds')}
                    >
                      View Available Bonds
                    </Button>
                  </div>
                )}
                <Table
                  aria-label="Usage history table"
                  css={{
                    height: "auto",
                    minWidth: "100%",
                  }}
                >
                  <Table.Header>
                    <Table.Column>DATE</Table.Column>
                    <Table.Column>EXPIRES</Table.Column>
                    <Table.Column>SIZE</Table.Column>
                    <Table.Column>USAGE</Table.Column>
                    <Table.Column>STATUS</Table.Column>
                  </Table.Header>

                  <Table.Body>
                    {bonds.map((item) => (
                      <Table.Row key={item.bondId}>
                        <Table.Cell>{`${fromFullStringDateToShortString(String(item.createdAt))}`}</Table.Cell>
                        <Table.Cell>{`${fromFullStringDateToShortString(String(item.expireAt))}`}</Table.Cell>
                        <Table.Cell>{formatFileSize(item.bondQuantity, 0)}</Table.Cell>
                        <Table.Cell>
                          <div className="w-full max-w-[200px] sm:max-w-none">
                            <Progress
                              value={(item.consumedBondData / (item.bondQuantity)) * 100}
                              color="success"
                              size="sm"
                            />
                            <Text size={12} css={{ color: '$accents7' }}>
                              {formatFileSize(item.consumedBondData, 0)} used of {formatFileSize(item.bondQuantity)}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <Button size="sm" color={new Date(item.expireAt) <= new Date() ? "error" : "success"} light>
                            {new Date(item.expireAt) <= new Date() ? 'Expired' : 'Active'}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          )}
        </div>
      </Container>
    </div>
  );
}

export default BondsComponent;