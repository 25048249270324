import { useEffect, useState } from "react"
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook"
import { Grid, Input, Spacer, Tooltip, Button, Card, Text, Loading } from "@nextui-org/react"
import { BsCopy } from "react-icons/bs"
import toast from 'react-hot-toast';
import { PlanModalComponent } from "../plan-modal/plan-modal.component";
import { QuantdleLoader } from "../quantdle-loader/loader.component"
import { IoMdRefresh } from "react-icons/io";
import { set } from "date-fns";

export interface ApiKey {
    apiKey: string
    apiKeyId: string
}


export const ApiKeyComponent = () => {

    const { downloadsApi } = useQuantdleApi()

    const [apiKey, setApiKey] = useState<ApiKey | undefined>(undefined)
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const handleOnApiKeyIdCopy = () => {
        navigator.clipboard.writeText(apiKey.apiKeyId)
        toast.success("Api ID Copied!")
    }
    
    const handleOnApiKeyCopy = () => {
        navigator.clipboard.writeText(apiKey.apiKey)
        toast.success("Api Key Copied!")
    }
    
    const handleCreateApiKey = () => {
        setLoading(true)
        if (!downloadsApi) return
        downloadsApi.apiKeyCreateGet().then((res) => {
            setApiKey({
                apiKey: res.data.value,
                apiKeyId: res.data.id
            })
        }).catch((err) => {
            // if error status is 403, then the user must upgrade their plan
            console.log(err)
            if(err.response.status === 403) {
                toast.error("Oops! This feature seems out of your plan!")
                setShowPlanModal(true)
            }
            
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
            
            setLoading(true)
            if (!downloadsApi) return
    
            downloadsApi.apiKeyGetGet().then((res) => {
                setApiKey({
                    apiKey: res.data.value,
                    apiKeyId: res.data.id
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    
        }, [downloadsApi])

    return (
            <Card css={{ w: '100%' }}>
            <Card.Header>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}>
                        <Text h3 css={{ mb: 0 }}>API Key</Text>
                        <Text css={{ color: '$accents7' }}>
                            Use this API key to access our data programmatically our through our Auto-MT5 EA.
                        </Text>
                    </div>
            </Card.Header>
            <Card.Body>
                <Grid style={{minWidth: '400px', height:'100%', display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{
                            position: 'relative',
                            width: '100%',
                            overflow: 'hidden'
                    }}
                    >
                        <div style={{display:'flex',  alignItems: 'center', justifyContent: 'center'}}>
                            <Input readOnly style={{minWidth: '339px'}} labelPlaceholder="Api Key ID" initialValue={apiKey?.apiKeyId ?? ''}/>
                            <div style={{cursor: 'pointer', marginLeft: 5}} onClick={handleOnApiKeyIdCopy}>
                                <Tooltip trigger='hover' content={"Copy Api key ID"} placement='top'>
                                    <BsCopy />
                                </Tooltip>
                            </div>
                        </div>
                        <Spacer y={2} />
                        <div style={{display:'flex',  alignItems: 'center', justifyContent: 'center'}}>
                            <Input.Password readOnly style={{minWidth: '300px', maxWidth: '300px'}} labelPlaceholder="Api Key" initialValue={apiKey?.apiKey ?? ''}/>
                            <div style={{cursor: 'pointer', marginLeft: 5}} onClick={handleOnApiKeyCopy}>
                                <Tooltip trigger='hover' content={"Copy Api key"} placement='top'>
                                    <BsCopy />
                                </Tooltip>
                            </div>
                        </div>
                        <Spacer y={2} />
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button
                            size="lg"
                            color={loading ? "secondary" : 'primary'}
                            flat
                            icon={loading ? <Loading size="sm" /> : <IoMdRefresh size={18} />}
                            onClick={!loading && handleCreateApiKey}
                            >
                                Refresh API Key
                            </Button>
                        </div>
                    </div>  
                </Grid>
            </Card.Body>
        </Card>
    )
        

}

