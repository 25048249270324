import { Card, Text, Button } from "@nextui-org/react"
import { LuDownload as Download } from "react-icons/lu";
import { CSSProperties } from 'react'

interface EaTutorialStyles {

    flexContainer: CSSProperties
    videoContainer: CSSProperties
    iframe: CSSProperties

}

export const EaTutorialComponent = () => {

    const styles: EaTutorialStyles = {
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        videoContainer: {
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%', // 16:9 aspect ratio
            borderRadius: '0.5rem',
            overflow: 'hidden',
        },
        iframe: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
            borderRadius: '0.5rem',
        },
    }

    return (
        <Card css={{ w: '100%' }}>
            <Card.Body>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <div style={styles.flexContainer}>
                        <Text h3 css={{ mb: 0 }}>MT5 Auto-EA</Text>
                        <Download size={24} style={{ color: 'var(--nextui-colors-primary)' }} />
                    </div>
                    <div style={styles.videoContainer}>
                        <iframe
                            src="https://www.youtube.com/embed/Cb-bBmW4gp4?si=ygTnU00RzE5XXjk_"
                            title="EA Tutorial"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={styles.iframe}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            size="lg"
                            flat
                            icon={<Download size={18} />}
                            color="primary"
                            css={{ w: '100%', '@sm': { w: 'auto' } }}
                        >
                            Download EA
                        </Button>
                    </div>
                        <Text size={12} css={{ color: '$accents7', ta: 'center' }}>
                            Version 1.0.0 | Windows & macOS
                        </Text>
                </div>
            </Card.Body>
            </Card>
    )

}